import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTagContext } from '../TagContext';
import '../styles/RecentAlbumsSection.css';
import { API_BASE_URL } from '../constants';

function RecentAlbumsSection() {
  const [albums, setAlbums] = useState([]);
  const { updateSelectedTags } = useTagContext();
  const navigate = useNavigate();

  useEffect(() => {
    const controller = new AbortController();
    const fetchRecentAlbums = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/albums?limit=5`, {
          signal: controller.signal,
          headers: {
            'Accept': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setAlbums(data);
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Fetch aborted');
          return;
        }
        console.error('Error fetching recent albums:', error);
      }
    };

    fetchRecentAlbums();
    return () => controller.abort();
  }, []);

  const handleTagClick = (tag, category) => {
    updateSelectedTags(tag.toLowerCase(), category);
    navigate('/album');
  };

  const renderUniqueTagButtons = (tags, category, albumCode) => {
    const uniqueTags = new Set();
    return tags.split(',')
      .map(tag => tag.trim())
      .filter(tag => tag !== '')  // 빈 문자열 제거
      .filter(tag => {
        const lowercaseTag = tag.toLowerCase();
        if (!uniqueTags.has(lowercaseTag)) {
          uniqueTags.add(lowercaseTag);
          return true;
        }
        return false;
      })
      .map(tag => (
        <button
          key={`${albumCode}-${tag}-${category}`}
          className={`recent-tag-button${category === 'genre' ? '-genre' : ''}`}
          onClick={(e) => {
            e.preventDefault();
            handleTagClick(tag, category);
          }}
        >
          {tag}
        </button>
      ));
  };

  return (
    <div className="recent-albums-section">
      <h2>New Release</h2>
      <div className="recent-albums-container">
        {albums.map((album) => (
          <Link to={`/album/${album['ALBUM: Code']}`} key={album['ALBUM: Code']} className="recent-album-card">
            <div className="recent-album-artwork">
              {album.has_artwork && (
                <img
                  src={`${API_BASE_URL}/artwork/${album['ALBUM: Code']}`}
                  alt={`${album['ALBUM: Title']} cover`}
                />
              )}
            </div>
            <h3>{album['ALBUM: Title']}</h3>
            <p></p>
            <div className="recent-tag-container">
              {renderUniqueTagButtons(album['ALBUM: Description'], 'description', album['ALBUM: Code'])}
              {renderUniqueTagButtons(album['TRACK: Genre'], 'genre', album['ALBUM: Code'])}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default RecentAlbumsSection;
