import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

// 전역 캐시 컨트롤 설정
const setupCacheControl = () => {
  // 서비스 워커 제거 (if exists)
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      for(let registration of registrations) {
        registration.unregister();
      }
    });
  }

  // 캐시 스토리지 초기화
  if ('caches' in window) {
    caches.keys().then(function(names) {
      for (let name of names) {
        caches.delete(name);
      }
    });
  }

  // 전역 fetch 인터셉터 설정
  const originalFetch = window.fetch;
  window.fetch = function(...args) {
    if (args[1] && args[1].method !== 'POST') {
      const timestamp = new Date().getTime();
      const url = new URL(args[0]);
      url.searchParams.set('t', timestamp);
      args[0] = url.toString();
      
      if (!args[1]) args[1] = {};
      if (!args[1].headers) args[1].headers = {};
      
      args[1].headers['Cache-Control'] = 'no-cache, no-store, must-revalidate';
      args[1].headers['Pragma'] = 'no-cache';
      args[1].headers['Expires'] = '0';
    }
    return originalFetch.apply(this, args);
  };
};

setupCacheControl();
library.add(fas);

console.log('App running at:', process.env.REACT_APP_HOST || 'localhost');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
