import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import LoginForm from '../components/LoginForm';
import NewsManagement from '../components/NewsManagement';
import Modal from '../components/Modal';
import { API_BASE_URL } from '../constants';
import '../styles/AdminPage.css';

function AdminPage({ isOpen, onClose }) {
    const { isLoggedIn, login, logout, checkAuthStatus } = useAuth();
    const [localIsLoggedIn, setLocalIsLoggedIn] = useState(isLoggedIn);
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isReloading, setIsReloading] = useState(false);

    useEffect(() => {
        const checkAuth = async () => {
            await checkAuthStatus();
            setLocalIsLoggedIn(isLoggedIn);
            if (isLoggedIn) {
                await fetchNews();
            }
            setLoading(false);  // 인증 확인 및 뉴스 로딩 후 로딩 상태 해제
        };
        
        setLoading(true);  // 체크 시작 전 로딩 상태 설정
        checkAuth();
    }, [checkAuthStatus, isLoggedIn]);

    const fetchNews = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/news`, {
                credentials: 'include'
            });
            if (!response.ok) {
                throw new Error('Failed to fetch news');
            }
            const data = await response.json();
            setNews(data.news);
        } catch (error) {
            console.error('Error fetching news:', error);
            // 에러 발생 시에도 로딩 상태 해제
            setLoading(false);
        }
    };

    const handleLogin = async (username, password) => {
        console.log('Login attempt:', username);
        const success = await login(username, password);
        if (success) {
            console.log('Login successful');
            setLocalIsLoggedIn(true);
            fetchNews();
        } else {
            console.log('Login failed');
            alert('Login failed. Please try again.');
        }
    };
    const handleReloadData = async () => {
        setIsReloading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/reload_data`, {
                method: 'POST',
                credentials: 'include'
            });
            if (!response.ok) {
                throw new Error('Failed to reload data');
            }
            const result = await response.json();
            alert(result.message);
        } catch (error) {
            console.error('Error reloading data:', error);
            alert('Failed to reload data. Please try again.');
        } finally {
            setIsReloading(false);
        }
    };
    const handleLogout = () => {
        logout();
        onClose();
    };

    if (!isOpen) return null;

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={localIsLoggedIn ? 'large' : 'small'}>
            <div className="admin-page">
                <h1>{localIsLoggedIn ? 'Admin Panel' : 'Login'}</h1>
                {loading ? (
                    <p>Loading...</p>
                ) : !localIsLoggedIn ? (
                    <LoginForm onLogin={handleLogin} />
                ) : (
                    <>
                        <NewsManagement news={news} onUpdate={fetchNews} />
                        <div className="admin-buttons">
                            <button 
                                className="reload-data-button" 
                                onClick={handleReloadData}
                                disabled={isReloading}
                            >
                                {isReloading ? 'Reloading...' : 'Reload Data'}
                            </button>
                            <button className="logout-button" onClick={handleLogout}>Logout</button>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
}

export default AdminPage;