import React, { createContext, useState, useEffect, useContext } from 'react';
import { API_BASE_URL } from './constants';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(() => {
        return localStorage.getItem('isLoggedIn') === 'true';
    });

    useEffect(() => {
        localStorage.setItem('isLoggedIn', isLoggedIn);
    }, [isLoggedIn]);

    const checkAuthStatus = async () => {
        try {
            console.log("Checking auth status");
            const response = await fetch(`${API_BASE_URL}/check_auth`, {
                credentials: 'include'
            });
            console.log("Auth status response:", response.status);
            const data = await response.json();
            console.log("Auth status data:", data);
            setIsLoggedIn(data.authenticated);
            return data.authenticated;  // 인증 상태 반환
        } catch (error) {
            console.error('Error checking auth status:', error);
            setIsLoggedIn(false);
            return false;
        }
    };

    const login = async (username, password) => {
        try {
            console.log('Sending login request');
            const response = await fetch(`${API_BASE_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
                credentials: 'include'
            });
            console.log('Login response status:', response.status);
            const data = await response.json();
            if (response.ok) {
                console.log('Login response data:', data);
                setIsLoggedIn(true);
                localStorage.setItem('isLoggedIn', 'true');
                await checkAuthStatus(); // 로그인 후 즉시 인증 상태 확인
                return true;
            } else {
                console.log('Login error:', data);
                return false;
            }
        } catch (error) {
            console.error('Login error:', error);
            return false;
        }
    };

    const logout = async () => {
        try {
            await fetch(`${API_BASE_URL}/logout`, {
                method: 'POST',
                credentials: 'include'
            });
            setIsLoggedIn(false);
            localStorage.removeItem('isLoggedIn');
            await checkAuthStatus(); // 로그아웃 후 상태 재확인
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, login, logout, checkAuthStatus }}>
            {children}
        </AuthContext.Provider>
    );
};